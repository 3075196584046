<template>
  <mi-dialog
    :close-icon="false"
    :model-value="true"
    class="combinations-dialog"
    full-width
    full-height
    no-esc-dismiss
    no-backdrop-dismiss
    seamless
  >
    <!-- Header -->
    <combinations-header
      v-model:show-ambiguous="showAmbiguous"
      v-model:only-incomplete="onlyIncomplete"
      :correlation-id="correlationId"
      :is-exporting="isTableDataExporting"
      :search-string="querySearchString"
      @click:auto-swap="autoSwapColumns"
      @click:export-data="exportTableData"
    >
      <mi-resize-observer @resize="handleCombinationsHeaderResize"></mi-resize-observer>
    </combinations-header>

    <!-- Table -->
    <combinations-table
      ref="combinationsTable"
      :correlation-id="correlationId"
      :show-ambiguous="showAmbiguous"
      :only-incomplete="onlyIncomplete"
      class="combinations-dialog__table"
    ></combinations-table>
  </mi-dialog>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'
  import { exportFile } from 'quasar'

  import { exportVariantTreeTable } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { SET_IS_COMBINATIONS_TABLE_LOADING } from '@/store/modules/combinations/mutationTypes'
  import { VM_AUTO_SWAP_COLUMN, VM_COMB_TABLE_EXPORT } from '@/utils/analytics/constants'

  import { buildExcelName } from '@/components/saved-tables/utils'
  import CombinationsHeader from './combinations-header/CombinationsHeader.vue'
  import CombinationsTable from './combinations-table/CombinationsTable.vue'

  export default {
    name: 'Combinations',
    components: {
      CombinationsTable,
      CombinationsHeader
    },
    beforeRouteEnter(to, { name, query } = {}, next) {
      next(vm => {
        if (name === 'search' && query.searchString) {
          vm.querySearchString = query.searchString
        }
      })
    },
    props: {
      correlationId: {
        type: String,
        required: true
      }
    },
    data: () => ({
      isTableDataExporting: false,
      showAmbiguous: true,
      onlyIncomplete: false,
      querySearchString: ''
    }),
    computed: {
      ...mapGetters('combinations', [
        'aliasesAsRequestParams',
        'filtersAsRequestParams',
        'sortersAsRequestParams'
      ]),
      ...mapState('search', ['resultsFilterExcel'])
    },
    methods: {
      ...mapMutations('combinations', { SET_IS_COMBINATIONS_TABLE_LOADING }),

      async autoSwapColumns() {
        this.SET_IS_COMBINATIONS_TABLE_LOADING(true)

        try {
          this.$refs.combinationsTable?.refreshTabulator()

          // Analytics
          recordAnalytics(VM_AUTO_SWAP_COLUMN)
        }
        catch {
          this.SET_IS_COMBINATIONS_TABLE_LOADING()
        }
      },
      async exportTableData(tableName = '') {
        const { showCodeId, showName, splitCodeIdAndName } = this.resultsFilterExcel

        this.isTableDataExporting = true

        try {
          const excelFile = await exportVariantTreeTable({
            showCodeId,
            showName,
            splitCodeIdAndName,
            showAmbiguous: this.showAmbiguous,
            filters: this.filtersAsRequestParams,
            sorters: this.sortersAsRequestParams,
            aliases: this.aliasesAsRequestParams,
            correlationId: this.correlationId
          })

          const vmExcelName = this.buildVMTExcelName(tableName)

          exportFile(vmExcelName, new Blob([excelFile]))

          // Analytics
          recordAnalytics(VM_COMB_TABLE_EXPORT)
        }
        finally {
          this.isTableDataExporting = false
        }
      },
      handleCombinationsHeaderResize({ height } = {}) {
        const combinationsDialogEl = document.querySelector('.combinations-dialog')
        const pxHeight = `${ height }px`

        combinationsDialogEl?.style?.setProperty('--combinations-header-height', pxHeight)
      },
      buildVMTExcelName(tableName = '') {
        const { query } = this.$route

        const pp = query?.pp ? atob(query.pp) : ''
        const pmName = query?.productModelBusinessName ? atob(query.productModelBusinessName) : ''

        const excelName = buildExcelName(tableName, pp, pmName)

        return excelName
      }
    }
  }
</script>

<style lang="scss">
  $combinations-dialog-flex-gap: 1.5rem;

  .combinations-dialog {
    --combinations-header-height: 40px;
  }

  .combinations-dialog .q-dialog__inner {
    padding: 0;

    .mi-card__section {
      display: flex;
      flex-direction: column;
      gap: $combinations-dialog-flex-gap;
      min-width: 1200px;
      height: 90%;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .combinations-dialog__table {
    height: calc(100% - var(--combinations-header-height) - #{$combinations-dialog-flex-gap});
  }
</style>
