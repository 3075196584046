<template>
  <mi-dialog
    :model-value="modelValue"
    class="combinations-selection-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-none q-mb-lg"> Selection details </h5>

    <!-- Available actions -->
    <mi-list class="q-mb-md">
      <mi-list-item
        v-for="(action, index) in availableActionsWithCollection"
        :key="index"
        class="q-px-none"
      >
        <mi-list-item-section>
          <mi-list-item-label class="text-weight-bold text-body2"> {{ action.name }} </mi-list-item-label>
          <mi-list-item-label class="text-weight-regular" caption>
            {{ action.description }}
            <span v-if="action.isNameRequired" class="text-weight-bold"> (name is required) </span>
          </mi-list-item-label>
        </mi-list-item-section>
      </mi-list-item>
    </mi-list>

    <!-- Save button -->
    <template #actions>
      <mi-btn @click="openSelectedElementsInSearchPage"> Open </mi-btn>
      <template v-if="$canAccess('CREATE_COLLECTIONS')">
      </template>
    </template>
  </mi-dialog>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'

  import { postSummary } from '@/api'
  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { required } from '@/utils/validators'

  import { SEARCH_RESULT_TYPES } from '@/constants'
  import { CLEAR_SELECTED_ELEMENTS, UPDATE_SELECTED_ELEMENTS } from '@/store/modules/search/mutationTypes'
  import { VM_OPENED_COLLECTION, VM_SAVED_COLLECTION } from '@/utils/analytics/constants'

  export default {
    name: 'CombinationsTableSelectionDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      selectedComponents: {
        type: Array,
        required: true
      },
      selectedOptions: {
        type: Array,
        required: true
      }
    },
    emits: ['update:model-value'],
    data: () => ({
      isCollectionSaving: false,
      collectionName: '',
      validationRules: [required],
      availableActionsWithCollection: [
        { name: 'Open', description: 'Visualize selected elements in the "Search" page', isNameRequired: false }
        /* { name: 'Save & Open', description: 'Save and visualize collection', isNameRequired: true },
        { name: 'Save', description: 'Save collection', isNameRequired: true } */
      ]
    }),
    computed: {
      ...mapGetters('product-model', ['selectedProductModel'])
    },
    methods: {
      ...mapMutations('search', { CLEAR_SELECTED_ELEMENTS, UPDATE_SELECTED_ELEMENTS }),

      async saveCollection(openSearchPage = false) {
        const isCollectionNameValid = await this.$refs?.collectionName?.validate?.()

        if (isCollectionNameValid) {
          this.isCollectionSaving = true

          try {
            await postSummary({
              name: this.collectionName,
              pmEncodedBusinessName: this.selectedProductModel.encodedBusinessName,
              options: this.selectedOptions,
              components: this.selectedComponents
            })

            if (openSearchPage) {
              this.openSelectedElementsInSearchPage()
            }
            else {
              this.emitUpdateModelValue()
            }

            notify({
              title: 'Saved',
              content: `Collection "${ this.collectionName }" was successfully saved`,
              type: 'positive'
            })

            // Analytics
            recordAnalytics(VM_SAVED_COLLECTION)
          }
          finally {
            this.isCollectionSaving = false
          }
        }
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      },
      openSelectedElementsInSearchPage() {
        this.CLEAR_SELECTED_ELEMENTS(SEARCH_RESULT_TYPES.OPTIONS)
        this.CLEAR_SELECTED_ELEMENTS(SEARCH_RESULT_TYPES.COMPONENTS)

        this.UPDATE_SELECTED_ELEMENTS({
          elementsType: SEARCH_RESULT_TYPES.OPTIONS,
          subElementsType: SEARCH_RESULT_TYPES.CHOICES,
          elements: this.selectedOptions
        })
        this.UPDATE_SELECTED_ELEMENTS({
          elementsType: SEARCH_RESULT_TYPES.COMPONENTS,
          subElementsType: SEARCH_RESULT_TYPES.COMPONENT_VARIANTS,
          elements: this.selectedComponents
        })

        // Analytics
        recordAnalytics(VM_OPENED_COLLECTION)

        this.emitUpdateModelValue()
        this.$nextTick(() => {
          this.$router.push({ name: 'search' })
        })
      }
    }
  }
</script>

<style lang="scss">
  .hidden-btns {
    display: none;
  }

  .combinations-selection-dialog .q-dialog__inner > .mi-card {
    width: 560px;
    max-width: 560px;
    min-width: 560px;
  }
</style>
