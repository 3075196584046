<template>
  <div class="col-grow relative-position">
    <!-- Combinations table (tabulator) -->
    <div ref="combinationsTable" class="mi-tabulator compact"></div>

    <!-- Selection details dialog -->
    <selection-dialog
      v-model="isSelectionDialogShown"
      :selected-components="rowSelectedComponents"
      :selected-options="rowSelectedOptions"
    >
    </selection-dialog>
    <!-- Data loading spinner -->
    <mi-inner-loading class="bg-white" :showing="isCombinationsTableLoading">
      <mi-spinner size="70px"></mi-spinner>
      <h6 class="q-mt-lg q-mb-none"> {{ combinationsTableLoadingContent }} </h6>
    </mi-inner-loading>

    <!-- No combinations found -->
    <div
      v-if="!(isCombinationsTableLoading || tableRows.length)"
      class="mi-tabulator__no-data-found-caption column flex-center bg-white absolute"
    >
      <mi-icon name="warning-circle" size="2rem"></mi-icon>
      <h6 class="q-mt-md q-mb-none"> No combinations found </h6>
    </div>
  </div>
</template>

  <script>
  import Tabulator from 'tabulator-tables'
  import { mapGetters, mapState, mapMutations } from 'vuex'

  import { getVariantTreeTableHeader, searchComponentVariant, searchChoices, swapVariantTreeTableColumns } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { base64EncodeUnicode } from '@/utils/base64'

  import { SEARCH_RESULT_TYPES, COMBINATIONS_STATES, PM_TYPE } from '@/constants'
  import { VARIANT_TREE_TABLE_CONTENT } from '@/api/rest/variant-tree/endpoints'
  import {
    SET_APPLIED_FILTERS,
    SET_APPLIED_SORTERS,
    SET_IS_COMBINATIONS_TABLE_LOADING,
    SET_COMBINATIONS_TABLE_LOADING_CONTENT
  } from '@/store/modules/combinations/mutationTypes'
  import { VM_SWAP_COLUMN } from '@/utils/analytics/constants'

  import SelectionDialog from './CombinationsTableSelectionDialog.vue'

  const FROZEN_FIELD_NAME = 'rowNumber'

  const CSS_COL_CLASS = 'mi-tabulator__header-cell'
  const CSS_ROW_CLASS = 'mi-tabulator__header-cell--row-number'

  export default {
    name: 'CombinationsTable',
    components: { SelectionDialog },
    props: {
      correlationId: {
        type: String,
        required: true
      },
      showAmbiguous: {
        type: Boolean,
        required: true
      },
      onlyIncomplete: {
        type: Boolean,
        required: true
      }
    },
    data: () => ({
      isSelectionDialogShown: false,
      tabulator: {},
      tableHeader: [],
      tableRows: [],
      rowSelectedOptions: [],
      rowSelectedComponents: []
    }),
    computed: {
      ...mapGetters('combinations', ['aliasesAsRequestParams']),
      ...mapGetters('product-model'),
      ...mapState('combinations', [
        'appliedAliasesIds',
        'appliedFilters',
        'appliedSorters',
        'isCombinationsTableLoading',
        'combinationsTableLoadingContent'
      ]),
      ...mapState('product-model', ['selectedSearchProductModel']),
      ...mapState('search', ['resultsFilter'])
    },
    watch: {
      appliedAliasesIds: {
        handler() {
          this.refreshTabulator()
        },
        deep: true
      }
    },
    mounted() {
      this.initTabulator()

      this.$watch(
        () => [
          this.resultsFilter.showCodeId,
          this.resultsFilter.showName,
          this.showAmbiguous,
          this.onlyIncomplete
        ],
        this.setTabulatorData,
        { deep: true }
      )
    },
    methods: {
      ...mapMutations('combinations', {
        SET_APPLIED_FILTERS,
        SET_APPLIED_SORTERS,
        SET_IS_COMBINATIONS_TABLE_LOADING,
        SET_COMBINATIONS_TABLE_LOADING_CONTENT
      }),

      async getChoices(query = '') {
        const {
          encodedBusinessName,
          id,
          productModelType: pmType
        } = this.selectedSearchProductModel

        const pmIdentifier = pmType === PM_TYPE.PRODUCT_MODEL ? encodedBusinessName : id

        const { elements } = await searchChoices({ pmType, pmIdentifier, query })

        return elements
      },
      async getComponentVariants(query = '') {
        const {
          encodedBusinessName,
          id,
          productModelType: pmType
        } = this.selectedSearchProductModel

        const pmIdentifier = pmType === PM_TYPE.PRODUCT_MODEL ? encodedBusinessName : id

        const { elements } = await searchComponentVariant({
          pmType, pmIdentifier, query
        })

        return elements
      },
      async initTabulator() {
        this.SET_COMBINATIONS_TABLE_LOADING_CONTENT(COMBINATIONS_STATES.LOADING)

        this.tabulator = new Tabulator(this.$refs?.combinationsTable, {
          ajaxFiltering: true,
          ajaxSorting: true,
          ajaxProgressiveLoad: 'scroll',
          columnMinWidth: 60,
          height: '100%',
          maxHeight: '100%',
          layout: 'fitColumns',
          movableColumns: true,
          tooltips: true,
          headerSortTristate: true,
          placeholder: '',
          paginationSize: 50,
          selectable: 'highlight',
          rowClick: this.showSelectionDetailsDialog,
          dataSorted: this.updateSorters,
          dataFiltered: this.updateFilters,
          columnMoved: this.swapColumns,
          ajaxResponse: (url, params, response) => this.formatResponseForTabulator(response)
        })

        await this.setTabulatorHeader()
        this.setTabulatorData()
      },
      async refreshTabulator() {
        this.SET_APPLIED_FILTERS()
        this.SET_APPLIED_SORTERS()

        await this.setTabulatorHeader()
        this.setTabulatorData()
      },
      async setTabulatorData() {
        const { showCodeId, showName } = this.resultsFilter
        const endpointUrl = VARIANT_TREE_TABLE_CONTENT.replace(':correlationId', this.correlationId)
        const tableContentUrl = `${ process.env.VUE_APP_DOMAIN_URL }${ process.env.VUE_APP_VM_URL }${ endpointUrl }`
        const headers = {
          Authorization: `Bearer ${ this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_TOKEN) || '' }`
        }

        this.SET_IS_COMBINATIONS_TABLE_LOADING(true)

        try {
          await this.tabulator.setData(
            tableContentUrl,
            {
              ...this.aliasesAsRequestParams,
              showCodeId,
              showName,
              showAmbiguous: this.showAmbiguous,
              onlyIncomplete: this.onlyIncomplete
            },
            { headers }
          )

          this.tabulator.setColumns(this.getHeaderColumns())
          this.tabulator.setSort(this.appliedSorters)
          this.appliedFilters.forEach(({ field, value } = {}) => {
            this.tabulator.setHeaderFilterValue(field, value)
          })
        }
        finally {
          this.SET_IS_COMBINATIONS_TABLE_LOADING()
        }
      },
      async setTabulatorHeader() {
        const { header } = await getVariantTreeTableHeader({
          aliases: this.aliasesAsRequestParams,
          correlationId: this.correlationId
        })
        this.tableHeader = header
      },
      async showSelectionDetailsDialog(event, row = {}) {
        const tableRowIdx = row.getData().id - 1
        if (tableRowIdx <= -1) return

        const { cells } = this.tableRows[tableRowIdx]

        await this.updateRowSelectedOptionsAndComponents(cells)

        this.$nextTick(() => {
          this.isSelectionDialogShown = true
        })
      },
      async swapColumns(column, columns = []) {
        this.SET_COMBINATIONS_TABLE_LOADING_CONTENT(COMBINATIONS_STATES.REARRANGING)
        const { newTableHeader, hasHeaderChanged } = this.updateTableHeaderAfterColumnMoved(columns)

        if (!hasHeaderChanged) return

        const { showCodeId, showName } = this.resultsFilter

        this.tableHeader = newTableHeader

        await swapVariantTreeTableColumns({
          showCodeId,
          showName,
          correlationId: this.correlationId,
          header: this.tableHeader
        })

        this.setTabulatorData()

        // Analytics
        recordAnalytics(VM_SWAP_COLUMN)
      },
      async updateRowSelectedOptionsAndComponents(rowCells = []) {
        const optionTypeNameSingular = SEARCH_RESULT_TYPES.OPTIONS.slice(0, -1)
        const componentTypeNameSingular = SEARCH_RESULT_TYPES.COMPONENTS.slice(0, -1)
        const componentVariantsId2 = []
        const choicesId2 = []

        this.rowSelectedOptions = []
        this.rowSelectedComponents = []

        rowCells.forEach(({ codeId: subElementId2 } = {}, index) => {
          const { type: elementType } = this.tableHeader[index]

          if (elementType === optionTypeNameSingular) {
            choicesId2.push(base64EncodeUnicode(subElementId2))
          }
          else if (elementType === componentTypeNameSingular) {
            componentVariantsId2.push(base64EncodeUnicode(subElementId2))
          }
        })

        if (choicesId2.length) {
          const query = choicesId2.join(',')
          const choices = await this.getChoices(query)

          choices.forEach(choice => {
            this.rowSelectedOptions.push({
              id: choice.optionId,
              id2: choice.optionId2,
              name: choice.optionName,
              [SEARCH_RESULT_TYPES.CHOICES]: [choice]
            })
          })
        }

        if (componentVariantsId2.length) {
          const query = componentVariantsId2.join(',')
          const componentVariants = await this.getComponentVariants(query)

          componentVariants.forEach(componentVariant => {
            this.rowSelectedComponents.push({
              id: componentVariant.componentId,
              id2: componentVariant.componentId2,
              oid: componentVariant.componentOid,
              name: componentVariant.name,
              [SEARCH_RESULT_TYPES.COMPONENT_VARIANTS]: [componentVariant]
            })
          })
        }
      },
      formatResponseForTabulator({ lastPage, rows = [] } = {}) {
        const startIndex = this.tabulator.getDataCount?.() || 0

        if (lastPage > 1) {
          this.tableRows.push(...rows)
        }
        else {
          this.tableRows = rows
        }

        const data = rows.map((row, index) => {
          const result = { id: startIndex + index + 1 }
          row?.cells.forEach((cell, idx) => {
            const { field } = this.tableHeader[idx]
            result[field.replace(/\./g, '-')] = this.getCellLabel(cell, false)
          })
          return result
        })

        return { last_page: lastPage, data }
      },
      getCellLabel({ name = '', codeId = '', type = '' } = {}, showTitle = true) {
        const { showCodeId, showName } = this.resultsFilter

        let title = ''

        if (this.isYellowWorld(type, true)) {
          title += '<p class="mi-tabulator-header-info-title">Choices for <p/>'
        }
        else if (this.isGreenWorld(type, true)) {
          title += '<p class="mi-tabulator-header-info-title">Component Variants for <p/>'
        }
        else if (this.isSolutionElement(type)) {
          title += '<p class="mi-tabulator-header-info-title">Solution elements for <p/>'
        }

        // eslint-disable-next-line max-len,vue/max-len
        return `${ showTitle ? title : '' } ${ showCodeId ? codeId : '' }${ showCodeId && showName ? ' - ' : '' }${ showName ? name : '' }`
      },
      getHeaderColumns() {
        const col = [{
          formatter: 'rownum',
          field: FROZEN_FIELD_NAME,
          cssClass: `${ CSS_COL_CLASS } ${ CSS_ROW_CLASS }`,
          minWidth: 34,
          width: 34,
          resizable: false,
          headerSort: false,
          frozen: true
        }]

        const cols = this.tableHeader.map(({ name, codeId, field, type }) => ({
          title: this.getCellLabel({ name, codeId, type }),
          field: field.replace(/\./g, '-'),
          headerFilter: true,
          headerFilterPlaceholder: 'Search',
          cssClass: this.getHeaderColumnCssClass(type)
        }))

        return !this.tableRows.length ? [] : col.concat(cols)
      },
      isYellowWorld(type, onlyHeader = false) {
        const pluralType = `${ type }s`
        const { OPTIONS, CHOICES } = SEARCH_RESULT_TYPES
        return onlyHeader ? [OPTIONS].includes(pluralType) : [OPTIONS, CHOICES].includes(pluralType)
      },
      isGreenWorld(type, onlyHeader = false) {
        const pluralType = `${ type }s`
        const { COMPONENTS, COMPONENT_VARIANTS } = SEARCH_RESULT_TYPES
        return onlyHeader ? [COMPONENTS].includes(pluralType) : [COMPONENTS, COMPONENT_VARIANTS].includes(pluralType)
      },
      isSolutionElement(type) {
        const pluralType = `${ type }s`
        const { SOLUTION_ELEMENTS } = SEARCH_RESULT_TYPES
        return [SOLUTION_ELEMENTS].includes(pluralType)
      },
      getHeaderColumnCssClass(type = '') {
        let cssClass = CSS_COL_CLASS

        if (this.isYellowWorld(type)) {
          cssClass += ` ${ CSS_COL_CLASS }--yellow`
        }
        else if (this.isGreenWorld(type)) {
          cssClass += ` ${ CSS_COL_CLASS }--green`
        }
        else if (this.isSolutionElement(type, true)) {
          cssClass += ` ${ CSS_COL_CLASS }--blue`
        }

        return cssClass
      },
      updateFilters() {
        if (!this.tabulator.getHeaderFilters) return

        const tabulatorFilters = this.tabulator.getHeaderFilters?.() || []
        const filters = tabulatorFilters.map(({ field, value } = {}) => ({ field, value }))

        this.SET_APPLIED_FILTERS(filters)
      },
      updateSorters(tabulatorSorters = []) {
        if (!(this.appliedSorters.length || tabulatorSorters.length)) return
        const sorters = tabulatorSorters.map(({ field, dir } = {}) => ({ column: field, dir }))

        this.SET_APPLIED_SORTERS(sorters)
      },
      updateTableHeaderAfterColumnMoved(columns = []) {
        const newTableHeader = []
        let hasHeaderChanged = false

        columns
          .filter(({ _column } = {}) => _column.field !== FROZEN_FIELD_NAME)
          .forEach(({ _column } = {}, columnIdx) => {
            const headerItemIdx = this.tableHeader.findIndex(
              ({ field } = {}) => field === _column.field?.replace(/-/g, '.')
            )

            hasHeaderChanged = headerItemIdx !== columnIdx

            if (headerItemIdx > -1) {
              newTableHeader.push(this.tableHeader[headerItemIdx])
            }
          })

        return { newTableHeader, hasHeaderChanged }
      }
    }
  }
  </script>

  <style lang="scss">
    @import "@/styles/tabulator";

    p {
      margin: 0;
    }

    .mi-tabulator-header-info-title {
      font-family: "MAN Europe Condensed", sans-serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      text-align: left;
    }
  </style>
