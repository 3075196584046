<template>
  <div class="combinations-header row">
    <div class="col flex items-center no-wrap">
      <h3 class="q-my-none"> Possible combinations </h3>

      <!-- View selection -->
      <mi-btn-group v-if="false" class="text-no-wrap q-ml-lg" outline>
        <mi-btn color="accent"> Table View </mi-btn>
        <mi-btn color="accent" outline disable> Tree View </mi-btn>
      </mi-btn-group>
    </div>

    <div class="col-auto flex items-center justify-end no-wrap q-ml-md">
      <!-- Combinations drop down -->
      <div class="table-display__wrapper">
        <mi-btn-dropdown class="table-display-dropdown" flat label="Table Display">
          <div class="dropdown-wrapper dropdown-wrapper-gap">
            <span class="text-family-condensed filter-text text-bold self-start"> Filtering options </span>
            <mi-list>
              <mi-list-item class="filter-wrapper">
                <mi-list-item-section class="filter-switch">
                  <div class="switch-wrapper">
                    <mi-switch
                      v-model="FILTERING_OPTIONS.onlyIncomplete.state"
                      :disable="disableToggleIfNotComponentsAvailable"
                      class="only-incomplete"
                      @click="handleOptionsFilterToggle(
                        FILTERING_OPTIONS.onlyIncomplete.title,
                        FILTERING_OPTIONS.showAmbiguous.title
                      )"
                    >
                    </mi-switch>
                    <span class="text-body2 text-weight-regular"> Only Incomplete </span>
                  </div>
                  <div class="switch-wrapper">
                    <mi-switch
                      v-model="FILTERING_OPTIONS.showAmbiguous.state"
                      class="show-ambiguous"
                      disable
                      @click="handleOptionsFilterToggle(
                        FILTERING_OPTIONS.showAmbiguous.title,
                        FILTERING_OPTIONS.onlyIncomplete.title
                      )"
                    >
                    </mi-switch>
                    <span class="text-body2 text-weight-regular show-ambiguous-span"> Show Ambiguous </span>
                  </div>
                </mi-list-item-section>
              </mi-list-item>
            </mi-list>
            <span class="text-family-condensed filter-text text-bold self-start"> Table layout </span>
            <mi-list>
              <mi-list-item class="filter-wrapper">
                <mi-list-item-section class="filter-switch">
                  <div class="switch-wrapper">
                    <mi-switch
                      v-model="LAYOUT_OPTIONS.showCodeId.state"
                      @click="handleLayoutOptionsToggle(
                        LAYOUT_OPTIONS.showCodeId.ref, LAYOUT_OPTIONS.showName.ref, true
                      )"
                    >
                    </mi-switch>
                    <span class="text-body2 text-weight-regular"> {{ LAYOUT_OPTIONS.showCodeId.title }} </span>
                  </div>
                  <div class="switch-wrapper">
                    <mi-switch
                      v-model="LAYOUT_OPTIONS.showName.state"
                      @click="handleLayoutOptionsToggle(
                        LAYOUT_OPTIONS.showName.ref, LAYOUT_OPTIONS.showCodeId.ref, true
                      )"
                    >
                    </mi-switch>
                    <span class="text-body2 text-weight-regular"> {{ LAYOUT_OPTIONS.showName.title }} </span>
                  </div>
                </mi-list-item-section>
              </mi-list-item>
            </mi-list>
          </div>
        </mi-btn-dropdown>
      </div>

      <!-- Save for later button -->
      <mi-btn class="q-ml-sm" :disable="isTableSaved" flat @click="toggleDialog"> Save for later </mi-btn>

      <!-- Auto swap columns button -->
      <mi-btn class="q-ml-sm hidden" @click="$emit('click:auto-swap')"> Auto Swap Columns </mi-btn>

      <mi-btn-dropdown
        class="text-family-condensed table-display-dropdown q-ml-md"
        label="Export data"
        split
        @click="exportData"
      >
        <div class="dropdown-wrapper">
          <span class="filter-text text-bold q-mb-md self-start"> Exporting Settings </span>
          <mi-list>
            <mi-list-item class="filter-wrapper">
              <mi-list-item-section class="filter-switch">
                <div class="switch-wrapper">
                  <mi-switch
                    v-model="LAYOUT_EXCEL.showCodeId.state"
                    @click="handleLayoutOptionsToggle(LAYOUT_EXCEL.showCodeId.ref, LAYOUT_EXCEL.showName.ref)"
                  >
                  </mi-switch>
                  <span class="text-body2 text-weight-regular"> {{ LAYOUT_EXCEL.showCodeId.title }} </span>
                </div>
                <div class="switch-wrapper">
                  <mi-switch
                    v-model="LAYOUT_EXCEL.showName.state"
                    @click="handleLayoutOptionsToggle(LAYOUT_EXCEL.showName.ref, LAYOUT_EXCEL.showCodeId.ref)"
                  >
                  </mi-switch>
                  <span class="text-body2 text-weight-regular"> {{ LAYOUT_OPTIONS.showName.title }} </span>
                </div>
                <div class="switch-wrapper">
                  <mi-switch
                    v-model="LAYOUT_EXCEL.splitCodeIdAndName.state"
                    @click="handleLayoutOptionsToggle(LAYOUT_EXCEL.splitCodeIdAndName.ref, [
                      LAYOUT_EXCEL.showName.ref,
                      LAYOUT_EXCEL.showCodeId.ref
                    ])"
                  >
                  </mi-switch>
                  <span class="text-body2 text-weight-regular"> {{ LAYOUT_EXCEL.splitCodeIdAndName.title }} </span>
                </div>
              </mi-list-item-section>
            </mi-list-item>
          </mi-list>
          <mi-btn
            size="md"
            :loading="isExporting"
            class="q-ml-sm q-mt-lg export-button"
            color="accent"
            icon-right="download"
            icon-right-size="14px"
            @click="exportData"
          >
            Export Data
          </mi-btn>
        </div>
      </mi-btn-dropdown>

      <div class="flex items-center no-wrap q-ml-md">
        <!-- Go back button -->
        <mi-btn :to="goBackNavigationLink" icon="close" icon-type fab flat></mi-btn>
      </div>
    </div>

    <!-- Resize observer component is provided from parent here -->
    <slot></slot>
  </div>
  <div class="combinations-info-header row">
    <div v-if="productModelBusinessName" class="combinations-info-product-model">
      <span class="text-family-condensed text-weight-bold text-uppercase">Product Model</span>
      <span>{{ productModelBusinessName }}</span>
    </div>
    <div v-if="planningPeriodRange" class="combinations-info-pp">
      <span class="text-family-condensed text-weight-bold text-uppercase">Planning Period</span>
      <span>{{ planningPeriodRange }}</span>
    </div>
  </div>
  <saved-tables-dialog
    v-model="showDialog"
    :item="{ correlationId }"
    :on-save-callback="onSaveCallback"
    :on-save-in-progress="saveCombinationNameInProgress"
    :is-table-saved="isTableSaved"
  ></saved-tables-dialog>
</template>

<script>
  import { createNamespacedHelpers, mapGetters } from 'vuex'
  import { RESULTS_FILTER } from '@/constants'
  import { UPDATE_RESULTS_FILTER_EXCEL, UPDATE_RESULTS_FILTER_TABLE } from '@/store/modules/search/mutationTypes'
  import MiListItem from '@/packages/@mi-library/MiList/MiListItem.vue'
  import cloneDeep from 'lodash.clonedeep'
  import { getVariantTreeTableHeader, getVariantTreeCombination, saveVariantTreeCombination } from '@/api'
  import SavedTablesDialog from '@/components/saved-tables/SavedTablesDialog.vue'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { VM_SAVED_FOR_LATER_CALCULATION } from '@/utils/analytics/constants'
  import notify from '@/utils/notify'
  import { BASIC_INFO } from '@/plugins/quasar/notifications/notificationTypes'
  import { SAVED_TABLES_ROUTE } from '@/router/routeNames'
  import { handleOptionsToggle } from '@/utils/optionsToggle'
  import * as analyticsConstants from '@/utils/analytics/constants'

  const { mapState, mapMutations } = createNamespacedHelpers('search')

  const LAYOUT_FILTER_OPTIONS = {
    showCodeId: {
      title: 'Display ID',
      ref: 'showCodeId',
      id: 'SHOW_CODE_ID',
      state: true
    },
    showName: {
      title: 'Display Name',
      id: 'SHOW_NAME',
      ref: 'showName',
      state: true
    }
  }

  export default {
    name: 'CombinationsHeader',
    components: {
      SavedTablesDialog,
      MiListItem
    },
    props: {
      correlationId: {
        type: String,
        required: true
      },
      isExporting: {
        type: Boolean,
        required: false,
        default: false
      },
      searchString: {
        type: String,
        required: false,
        default: ''
      },
      showAmbiguous: {
        type: Boolean,
        required: false,
        default: true
      },
      onlyIncomplete: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: [
      'click:auto-swap',
      'click:export-data',
      'update:show-ambiguous',
      'update:only-incomplete'
    ],
    data: () => ({
      planningPeriodRange: null,
      tableName: null,
      productModelBusinessName: null,
      disableToggleIfNotComponentsAvailable: true,
      LAYOUT_OPTIONS: cloneDeep(LAYOUT_FILTER_OPTIONS),
      LAYOUT_EXCEL: {
        ...LAYOUT_FILTER_OPTIONS,
        splitCodeIdAndName: {
          title: 'Split name from ID cells',
          id: 'SPLIT_CODE_ID_AND_NAME',
          ref: 'splitCodeIdAndName',
          state: false
        }
      },
      FILTERING_OPTIONS: {
        onlyIncomplete: {
          title: 'onlyIncomplete',
          state: false
        },
        showAmbiguous: {
          title: 'showAmbiguous',
          state: true
        }
      },
      saveCombinationNameInProgress: false,
      isTableSaved: true,
      showDialog: false
    }),
    computed: {
      ...mapGetters('combinations', ['aliasesAsRequestParams']),
      ...mapState(['resultsFilter']),
      ...mapState(['selectedResultType']),

      goBackNavigationLink() {
        if (this.$route.params?.previousPage === SAVED_TABLES_ROUTE.name) {
          return { name: SAVED_TABLES_ROUTE.name }
        }

        const query = { resultType: this.selectedResultType }

        if (this.searchString) {
          query.searchString = this.searchString
        }

        return { name: 'search', query }
      }
    },
    created() {
      if (!this.$route.query) return
      if (this.$route.query.pp) {
        this.planningPeriodRange = atob(this.$route.query.pp)
      }
      if (this.$route.query.productModelBusinessName) {
        this.productModelBusinessName = atob(this.$route.query.productModelBusinessName)
      }
      else if (this.$route.query.exportName) {
        this.productModelBusinessName = this.$route.query.exportName
      }

      this.getTableHeader()
      this.checkIfTableSaved(this.correlationId)
    },
    methods: {
      ...mapMutations({ UPDATE_RESULTS_FILTER_TABLE, UPDATE_RESULTS_FILTER_EXCEL }),
      async getTableHeader() {
        const { header: tableHeader, name } = await getVariantTreeTableHeader({
          aliases: this.aliasesAsRequestParams,
          correlationId: this.correlationId
        })

        this.tableName = name
        const componentsHeaders = tableHeader.filter(header => header.type === 'component')
        this.disableToggleIfNotComponentsAvailable = !componentsHeaders.length
      },
      handleOptionsFilterToggle(currentToggle, toggleToBeDisabled) {
        this.FILTERING_OPTIONS[toggleToBeDisabled].state = !this.FILTERING_OPTIONS[currentToggle].state
        this.FILTERING_OPTIONS[currentToggle].state = !!this.FILTERING_OPTIONS[currentToggle].state
        this.triggerFilterEvents()
      },
      handleLayoutOptionsToggle(currentToggle, toggleToBeEnabled, isTableDisplay = false) {
        const layoutOptions = isTableDisplay ? this.LAYOUT_OPTIONS : this.LAYOUT_EXCEL

        handleOptionsToggle(layoutOptions, currentToggle, toggleToBeEnabled)

        this.triggerLayoutOptionsEvents(layoutOptions, isTableDisplay)
      },
      triggerFilterEvents() {
        this.$emit('update:show-ambiguous', this.FILTERING_OPTIONS.showAmbiguous.state)
        this.$emit('update:only-incomplete', this.FILTERING_OPTIONS.onlyIncomplete.state)
      },
      triggerLayoutOptionsEvents(layoutOptions, isTableDisplay) {
        // eslint-disable-next-line no-unused-vars
        const updates = Object.entries(layoutOptions).map(([key, { id, state }]) => ({
          filterType: RESULTS_FILTER[id],
          newVal: state
        }))

        updates.forEach(update => (
          isTableDisplay ? this.UPDATE_RESULTS_FILTER_TABLE(update) : this.UPDATE_RESULTS_FILTER_EXCEL(update)
        ))
      },
      exportData() {
        this.$emit('click:export-data', this.tableName)
      },
      async checkIfTableSaved(correlationId) {
        const response = await getVariantTreeCombination(correlationId)
        this.isTableSaved = response.showLater
      },
      toggleDialog() {
        this.showDialog = !this.showDialog
        recordAnalytics(analyticsConstants.VM_POSSIBLE_COMBINATIONS_SAVE_FOR_LATER)
      },
      async onSaveCallback({ correlationId, name }) {
        this.saveCombinationNameInProgress = true
        try {
          await saveVariantTreeCombination({ correlationId, name })
          recordAnalytics(VM_SAVED_FOR_LATER_CALCULATION)
        }
        catch (err) {
          throw new Error(
            `An error occurred trying to save the tables with the correlationId: ${ correlationId }`
          )
        }
        finally {
          this.tableIsSavedNotify()
          this.isTableSaved = true
          this.saveCombinationNameInProgress = false
          this.showDialog = false
        }
      },
      tableIsSavedNotify() {
        notify({
          content: 'Table is saved in “Saved Tables”.',
          type: BASIC_INFO,
          position: 'bottom-right'
        })
      }
    }
  }
</script>

<style lang="scss">
  .combinations-info-header {
    flex-direction: column;

    span {
      padding-right: .5rem;
    }
  }

  .dropdown-wrapper {
    padding: 8px;
    padding-right: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
  }

  .dropdown-wrapper-gap {
    gap: 16px;
  }

  .filter-switch {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }

  .switch-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 0;
    height: 24px;
  }

  .filter-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15.6px;
    padding-left: 8px;
  }

  .filter-text:first-child {
    padding-top: 10px;
  }

  .filter-wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }

  .export-button {
    width: 248px;
    height: 32px;
  }

  .only-incomplete .q-toggle__inner--falsy .q-toggle__track {
    // stylelint-disable declaration-no-important
    background-color: #5b6f85 !important;
  }

  .show-ambiguous .q-toggle__track {
    background-color: $mi-silver-400 !important;
  }

  .show-ambiguous-span {
    color: $mi-silver-400;
  }
</style>
